import { type } from '@testing-library/user-event/dist/type';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FaPlay, FaStop } from 'react-icons/fa';
import create_gif from '../../../../core/jsgif/wrapper';
import ConstGifCreate from '../../../../models/GifCreate/Constant';
import { GifOption, GifSize, ImageFile, ScalingType, SequenceType } from '../../../../models/GifCreate/Types';
import style from './Style.module.css';

interface PreviewProp {
  files: ImageFile[]
  selectedIndex: number
  onSelectionIndexChange: React.Dispatch<React.SetStateAction<number>>
}

function Preview({
  files,
  selectedIndex,
  onSelectionIndexChange,
}: PreviewProp) {

  /*************************************
   * 定義
   ************************************/

  //Canvas参照
  const canvas = useRef<HTMLCanvasElement>(null)
  //ダウンロードリンクの参照
  const downloadLink = useRef<HTMLAnchorElement>(null)
  //プレビュー枠の参照
  const imageDiv = useRef<HTMLDivElement>(null)

  //画像サイズ
  const [size, setSize] = useState<GifSize>(
    () => ConstGifCreate.GIF_SIZE_LIST.find(x => x.id == 5) as GifSize
  )

  //プレビュー用画像サイズ
  const [previewSize, setPreviewSize] = useState({ width: 0, height: 0})

  //オプション
  const [option, setOption] = useState<GifOption>({
    width: 300,
    height: 300,
    ms: 300,
    loopCount: 0,
    sequence: "normal",
    scaling: "contain",
    color: "#ffffff",
  })

  //タイマー
  const [timer, setTimer] = useState<NodeJS.Timer | null>(null)

  /*************************************
   * アクション
   ************************************/

  //画像サイズの変更イベント
  const handleSizeChange = useCallback(
    (id: number) => {
      const find = ConstGifCreate.GIF_SIZE_LIST.find(x => x.id == id)
      if (find) {
        setSize(find)
      }
    },
    [size],
  )

  //サイズ変更時のプレビューサイズ計算
  useEffect(() => {
    let [w, h] = size.id == ConstGifCreate.CUSTOM_SIZE_ID ? 
      [option.width, option.height] : 
      [size.width, size.height]
    const r = w / h   //  150 / 100

    const divH = (imageDiv.current?.clientHeight ?? 0) - 10
    const divW = (imageDiv.current?.clientWidth ?? 0) - 10

    if (h > divH) {
      h = divH
      w = h * r
    }

    if (w > divW) {
      w = divW
      h = w * r  
    }

    //プレビューサイズ設定
    setPreviewSize({width: w, height: h})
  }, [size, option])
  
  //作成オプションの入力変更時
  const handleChange = useCallback(
    (key: string, value: any) => {
      const newOption = { ...option }
      /* @ts-ignore */
      newOption[key] = value
      setOption(newOption)
    },
    [option],
  )

  //再生
  const play =
    () => {
      if (files.length == 0) return
      const saveIndex = selectedIndex
      const timer = setInterval(() => {
        onSelectionIndexChange(old => {
          if (option.sequence == "normal") {
            return old + 1 < files.length ? old + 1 : 0
          } else {
            return old >= 1 ? old - 1 : files.length - 1
          }
        })
      }, option.ms)
      setTimer(timer)
    }

  //停止
  const stop = useCallback(
    () => {
      if (timer) clearInterval(timer)
      setTimer(null)
    },
    [timer],
  )

  //ダウンロード
  const download = useCallback(
    async () => {

      if (!canvas.current) return
      if (!downloadLink.current) return
      if (files.length == 0) return

      const s = await create_gif(canvas.current,
        {
          ...option,
          width: size.id == ConstGifCreate.CUSTOM_SIZE_ID ? option.width : size.width,
          height: size.id == ConstGifCreate.CUSTOM_SIZE_ID ? option.height : size.height
        },
        files)

      if (s) {
        downloadLink.current.href = s
        downloadLink.current.click()
      }
    },
    [files, size, option],
  )

  /*************************************
   * VIEW
   ************************************/

  return (
    <div className="panel">
      <div className="panel-heading">
        <div className='is-flex is-align-items-center'>
          <span>OUTPUT</span>
          <div className='is-flex-grow-1' style={{marginLeft: '-7rem'}}>
            <div className='has-text-centered'>
              {timer == null ? (
                <button
                  className="button is-success is-light"
                  onClick={play}
                >
                  <span className="icon is-small">
                    <FaPlay />
                  </span>
                  <span>再生</span>
                </button>) :
                (
                  <button
                    className="button is-success is-danger"
                    onClick={stop}
                  >
                    <span className="icon is-small">
                      <FaStop />
                    </span>
                    <span>停止</span>
                  </button>
                )}
            </div>
          </div>
        </div>
      </div>

      <div className={style.container}>
        <div className={style.preview}>

          <div 
            className={style.image}
            ref={imageDiv}
          >
            {selectedIndex >= 0 && (
              <img
                src={files[selectedIndex].dataUrl}
                style={{
                  width: previewSize.width,
                  height: previewSize.height,
                  objectFit: option.scaling,
                  backgroundColor: option.color
                }}
              />
            )}
          </div>
        </div>
        {/* 設定 */}
        <div className={style.property}>
          {/* 設定-タイトル */}
          <div className={style.title}>
            設定
          </div>
          {/* 設定-フォーム */}
          <div className='p-2'>
            {/* サイズ */}
            <div className="field">
              <label className="label">サイズ</label>
              <div className="control">
                <div className="select">
                  <select value={size.id} onChange={e => handleSizeChange(parseInt(e.target.value))}>
                    {ConstGifCreate.GIF_SIZE_LIST.map(x => (
                      <option key={x.id} value={x.id}>{x.name}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            {/* カスタムサイズ */}
            {size.id == ConstGifCreate.CUSTOM_SIZE_ID && (
              <div className="field">
                <div className="control">
                  <span>横</span>
                  <input
                    className="input"
                    type="number"
                    style={{ width: "5.5rem" }}
                    step={10}
                    value={option.width}
                    max={3000}
                    min={50}
                    onChange={e => handleChange("width", parseInt(e.target.value))}
                  />
                  <span>縦</span>
                  <input
                    className="input"
                    type="number"
                    style={{ width: "5.5rem" }}
                    step={10}
                    value={option.height}
                    max={3000}
                    min={50}
                    onChange={e => handleChange("height", parseInt(e.target.value))}
                  />
                </div>
              </div>
            )}
            {/* フレーム遅延時間 */}
            <div className="field">
              <label className="label">フレーム遅延時間</label>
              <div className="control">
                <input
                  className="input"
                  type="number"
                  style={{ width: "7rem" }}
                  step={10}
                  value={option.ms}
                  max={10000}
                  min={10}
                  onChange={e => handleChange("ms", parseInt(e.target.value))}
                /> ms
              </div>
            </div>
            {/* ループ回数 */}
            <div className="field">
              <label className="label">ループ回数（０で無限）</label>
              <div className="control">
                <input
                  className="input"
                  type="number"
                  style={{ width: "5rem" }}
                  step={1}
                  value={option.loopCount}
                  min={0}
                  max={1000}
                  onChange={e => handleChange("loopCount", parseInt(e.target.value))}
                /> 回
              </div>
            </div>
            {/* 順序 */}
            <div className="field">
              <label className="label">順序</label>
              <div className="control">
                <label className="radio">
                  <input
                    type="radio"
                    name="sequence"
                    value="normal"
                    checked={option.sequence === "normal"}
                    onChange={e => handleChange("sequence", e.target.value)} />
                  通常
                </label>
                <label className="radio">
                  <input
                    type="radio"
                    name="question"
                    value="reversal"
                    checked={option.sequence === "reversal"}
                    onChange={e => handleChange("sequence", e.target.value)} />
                  反転
                </label>
              </div>
            </div>
            {/* 画像の拡大縮小 */}
            <div className="field">
              <label className="label">画像の拡大縮小</label>
              <div className="control">
                <div className="select">
                  <select value={option.scaling} onChange={e => handleChange("scaling", e.target.value)}>
                    <option value={"contain"}>画像全体を表示する</option>
                    <option value={"cover"}>領域全体を覆う</option>
                  </select>
                </div>
              </div>
            </div>
            {/* 背景色 */}
            <div className="field">
              <label className="label">背景色</label>
              <div className="control">
                <input
                  type="color"
                  value={option.color}
                  onChange={e => handleChange("color", e.target.value)} />
              </div>
            </div>
            <div>
              <button
                className="button is-success is-light"
                onClick={download}>GIFダウンロード</button>
              <a href="#" download="download" style={{ display: "none" }} ref={downloadLink}>download</a>
            </div>
          </div>
        </div>

        <canvas ref={canvas} style={{ display: "none", width: "360px", height: "240px" }}></canvas>
      </div>
    </div>
  )
}



export default Preview