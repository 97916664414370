import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ChangeEvent } from 'react'
import style from './Style.module.css';
import Encoding from 'encoding-japanese';
import { type } from 'os';
import FileDropzone from '../../ui-parts/FileDropzone/FileDropzone';
import { InputFile } from '../../../models/JoinText/Types';

function JoinFiles() {

  const [files, setFiles] = useState<InputFile[]>([])
  const [dragging, setDragging] = useState(false)
  const [enc, setEnc] = useState("UTF8")
  const refDownloadLink = useRef<HTMLAnchorElement>(null)
  const [selectedindexes, setSelectedindexes] = useState<{ [key: number]: boolean }>({})

  /**
   * ファイル選択の変更
   */
  const handleChange = useCallback(
    async (files: FileList) => {
      if (files) {
        await addFiles(files)
      }
    },
    [files],
  )

  /**
   * 選択状態の変更
   */
  const checkedChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, index: number) => {
      const a = { ...selectedindexes }
      a[index] = e.target.checked
      setSelectedindexes(a)
    },
    [selectedindexes]
  )

  /**
   * ファイルの追加
   * @param flist ファイルリスト
   */
  const addFiles = async (flist: FileList) => {
    let initEncode = files.length == 0

    for (let i = 0; i < flist.length; i++) {
      const f = new InputFile()
      f.name = flist[i].name
      f.size = flist[i].size
      f.type = flist[i].type
      const mimeType = f.type.split("/")
      f.target = mimeType.length >= 1 &&
        (mimeType[0] == "text" || mimeType[0] == "application")

      f.buffer = await readFile(flist[i])

      //先頭のファイルで結合後の文字コード初期値設定
      if (initEncode && f.target) {
        //文字コード変換
        const detectedEncoding = Encoding.detect(new Uint8Array(f.buffer))
        console.log(detectedEncoding)
        if (detectedEncoding !== false) {
          setEnc(detectedEncoding)
        }
        initEncode = false
      }

      setFiles(oldValue => [...oldValue, f])
    }
  }

  /**
   * ファイルの読み込み
   * @param f ファイル
   * @returns 
   */
  const readFile = (f: File): Promise<ArrayBuffer> => {
    return new Promise<ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        const { result } = reader;
        resolve(result as ArrayBuffer);
      });
      reader.addEventListener("error", () => {
        reject(reader.error);
      });
      reader.readAsArrayBuffer(f);
    });
  }

  /**
   * 上へ
   */
  const handleUp = useCallback(
    () => {
      moveRow(-1)
    },
    [files, selectedindexes],
  )

  /**
   * 下へ
   */
  const handleDown = useCallback(
    () => {
      moveRow(1)
    },
    [files, selectedindexes],
  )

  /**
   * 行移動
   */
  const moveRow = useCallback(
    (move: number) => {
      const indexes = files.
        map((x, i) => i).
        filter(i => selectedindexes[i]).
        sort((a, b) => move < 0 ? a - b : b - a)

      let st = 0
      if (indexes[0] == (move < 0 ? 0 : files.length - 1)) {
        st = files.length
        for (let i = 1; i < indexes.length; i++) {
          const d = indexes[i - 1] - (indexes[i] + move)
          if (d != 0) {
            st = i
            break
          }
        }
      }

      console.log(st)

      let newFilesWork = files.map(x => x)
      indexes.slice(st).forEach(index => {
        if (move < 0) {
          //上への移動
          newFilesWork.splice(index + move, 2, newFilesWork[index], newFilesWork[index - 1])
        } else {
          //下への移動
          newFilesWork.splice(index, 2, newFilesWork[index + 1], newFilesWork[index])
        }
      })

      //選択情報も更新
      const newSelectedindexes: { [key: number]: boolean } = {}
      indexes.forEach((rowIndex, index) => {
        if (index < st) {
          newSelectedindexes[rowIndex] = true
        } else {
          newSelectedindexes[rowIndex + move] = true
        }
      })

      setSelectedindexes(newSelectedindexes)
      setFiles(newFilesWork)
    },
    [files, selectedindexes],
  )

  /**
   * 選択を削除
   */
  const handleDelete = useCallback(
    () => {
      const newFiles = files.filter((x, i) => !selectedindexes[i])
      setFiles(newFiles)
      setSelectedindexes({})
    },
    [files, selectedindexes],
  )

  const hasSelected = (): boolean => {
    return files.
      map((x, i) => i).
      filter(i => selectedindexes[i]).
      length > 0
  }

  /**
   * 結合実行ボタンの押下
   */
  const handleExecute = useCallback(
    () => {
      let joinResultArray: number[] = []  //文字コード変換後の配列
      let appendNewLine = false     //改行を挿入するかどうか

      //選択されたファイルの数だけ文字コード変換＋結合
      files.filter(f => f.target).forEach((f, index) => {
        if (!(f.buffer instanceof ArrayBuffer)) {
          return
        }

        //２ファイル目以降で前のファイルの末尾が改行コードのみで終わっていない場合は改行コード挿入
        if (appendNewLine) {
          console.log("appendNewLine")
          joinResultArray.push(10)
        }

        //文字コード変換
        const conv = Encoding.convert(
          new Uint8Array(f.buffer),
          {
            to: enc as Encoding.Encoding,
            from: 'AUTO',
          }
        )

        //次のファイルを結合する前に改行コードを挿入するか判定
        appendNewLine = true
        if (conv.length >= 2 && conv[conv.length - 1] == 13 && conv[conv.length - 2] == 10) {
          appendNewLine = false
        } else if (conv.length >= 1 && conv[conv.length - 1] == 10) {
          appendNewLine = false
        }

        //ファイル結合
        joinResultArray = [...joinResultArray, ...conv];
      });

      //BLOBに変換してダウンロード
      const uInt8List = new Uint8Array(joinResultArray);
      const blob = new Blob([uInt8List], { type: 'text/plain' });
      if (refDownloadLink.current) {
        refDownloadLink.current.href = window.URL.createObjectURL(blob)
        refDownloadLink.current.click()
      }

      //console.log(files)
    },
    [files, enc],
  )


  return (
    <div>
      <FileDropzone multiple={true} onChange={handleChange} autoClear={true} />

      {/* <div style={{marginTop:200}}></div>
      <div
        className={`${style.dragzone} ${dragging ? style.dragging : ""}`}
        onDragOver={handleDragover}
        onDragLeave={handleDragleave}
        onDrop={handleDrop}>
        ここにファイルをドロップ<br />
        または<br />
        <input ref={refFileElement} onChange={handleChange} type="file" />
      </div> */}
      <div>
        <h3>選択した結合元ファイル</h3>
        <div>
          <button disabled={!hasSelected()} onClick={handleUp}>上へ</button>
          <button disabled={!hasSelected()} onClick={handleDown}>下へ</button>
          <button
            disabled={!hasSelected()}
            onClick={handleDelete}
            style={{ marginLeft: "1rem" }}
          >選択を削除</button>
        </div>
        <table className={style.fileTable}>
          <thead>
            <tr>
              <th></th>
              <th>ファイル名</th>
              <th>size</th>
              <th>タイプ</th>
            </tr>
          </thead>
          <tbody>
            {files.map((x, i) => (
              <tr key={i} className={x.target ? '' : style.notarget}>
                <td>
                  <input
                    onChange={(e) => checkedChange(e, i)}
                    type="checkbox"
                    checked={selectedindexes[i] === true} />
                </td>
                <td>{x.name}</td>
                <td>{x.size} Byte</td>
                <td>{x.type}</td>
              </tr>
            ))}
            {files.length == 0 && (
              <tr>
                <td colSpan={4}>ファイルがまだ選択されていません</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div>
        <h3>結合</h3>
        <div>
          <label>結合後の文字コード：</label>
          <select value={enc} onChange={e => setEnc(e.target.value)}>
            <option value={'UTF8'}>UTF-8</option>
            <option value={'UTF16'}>UTF-16</option>
            <option value={'SJIS'}>SJIS</option>
          </select>
        </div>
        <div>
          <button
            onClick={handleExecute}
            className={style.btnExecute}
          >結合実行</button>
          <a ref={refDownloadLink}
            style={{ display: "none" }}
            download={"Join"}>down</a>
        </div>
      </div>
    </div>
  )
}

export default JoinFiles