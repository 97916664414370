export default class StringUtil {

  /**
   * 全角文字かチェックします。
   * @param src チェック対象の文字列
   * @returns 全角文字の場合 true
   */
  static isFullWidth(src: string) {
    return (String(src).match(/[\x01-\x7E\uFF65-\uFF9F]/)) ? false : true;
  }

  /**
   * バイト単位で文字列切り取り
   * @param src 対象の文字列
   * @param start 開始バイト
   * @param size 切り取るバイト数
   * @returns バイト単位で切り取った文字列
   */
  static substrByte(src: string, start: number, size: number) {
    let result = ""
    let count1 = 0
    let count2 = 0

    for(let i = 0; i < src.length; i++) {
      const c = src.charAt(i)
      const char_size = StringUtil.isFullWidth(c) ? 2 : 1

      if (count1 >= start) {
        count2 += char_size + (count2 == 0 ? count1 - start : 0)
        if (count2 <= size) {
          result += c
        } else {
          break
        }  
      }
      count1 += char_size
    }

    return result
  }

  static toBlob(base64Data: string, contentType: string) {
    contentType = contentType || '';
    const sliceSize = 1024;
    const byteCharacters = window.atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
}

}