import React, { useCallback, useMemo, useState } from 'react'
import { Sentence } from '../../../../models/GobiChecker/Types'
import style from './Style.module.css';

interface ResultProp {
  value: string,
  sentences?: Sentence[]
}

/**
 * 語尾チェッカーの入力部分
 */
function Result({
  value,
  sentences,
}: ResultProp) {

  const createSentenceElem = useCallback((sentence: Sentence) => {
    const split = sentence.withoutEndings.split("、")
    return split.map((str, j) => (
      <React.Fragment key={j}>
        <span>{str}</span>
        {j < split.length - 1 && 
          (<span 
            className={`${sentence.duplicateKutoten ? style.duplicate : ''}`}
           >、</span>)}
      </React.Fragment>
    ))
  }, [])

  return (
    <div className='panel'>
      <h2 className="is-size-5 panel-heading">結果</h2>
      <div className="panel-block" style={{ display: "block" }}>
        <div>
          <div>
            <i className={style.duplicate_guide} /><span>の部分で同じ語尾が連続しています</span>
          </div>
          <div style={{ border: "1px solid #333" }}>
            {sentences?.map((sentence, i) => (
              <React.Fragment key={i}>
                {createSentenceElem(sentence)}
                <span
                  className={`${sentence.duplicate ? style.duplicate : style.no_duplicate}`}
                >{sentence.endings}</span>
                {sentence.lineBreakAfter && (<br />)}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Result