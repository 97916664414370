import React, { ChangeEvent, DragEvent, forwardRef, ForwardRefRenderFunction, ReactNode, useCallback, useImperativeHandle, useRef, useState } from 'react'
import style from './Style.module.css';

/**
 * プロパティ
 */
interface FileDropzoneProp {
  /**
   * ファイル選択時のイベント
   */
  onChange: (files: FileList) => Promise<void>
  /**
   * 複数ファイル選択を許可するか
   */
  multiple?: boolean
  /**
   * onChange後、自動で選択をクリアするか
   */
  autoClear?: boolean
  /**
   * 子要素
   */
  children?: ReactNode
}

// 公開したいメソッドの定義
export interface FileDropzoneHandles {
  openDialog(): void;
}

const ChildComponent: ForwardRefRenderFunction<FileDropzoneHandles, FileDropzoneProp> = (
  {
    onChange,
    multiple,
    autoClear,
    children,
  },
  ref
) => {
  const [dragging, setDragging] = useState(false)
  const refFileElement = useRef<HTMLInputElement>(null)

  /**
   * ドロップ時の処理
   */
  const handleDrop = useCallback(
    async (e: React.DragEvent<HTMLDivElement>) => {
      //console.log("handleDrop")
      e.stopPropagation()
      e.preventDefault()

      if (e.dataTransfer.files?.length > 0 && refFileElement.current) {
        if (multiple) {
          await onChange(e.dataTransfer.files)
        } else {
          const dt = new DataTransfer()
          dt.items.add(e.dataTransfer.files[0])
          refFileElement.current.files = dt.files
          await onChange(dt.files)
          if (autoClear) {
            refFileElement.current.value = "";
          }
        }
      }
      setDragging(false)
    },
    [onChange, refFileElement.current])

  /**
   * ファイル選択(input type="file"からの選択）
   */
  const handleChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        await onChange(e.target.files)
        if (autoClear && refFileElement.current) {
          refFileElement.current.value = "";
        }
      }
    },
    [onChange])

  /**
   * Dragover or Dragleave
   * @param e 
   */
  const handleDragoverOrLeave = useCallback((e: DragEvent<HTMLDivElement>) => {
    //console.log("handleDragoverOrLeave")
    e.stopPropagation()
    e.preventDefault()
    setDragging(e.type === "dragover")
  }, []);

  useImperativeHandle(ref, () => ({
    openDialog() {
      refFileElement.current?.click()
    }
  }));

  return (
    <div
      onDragOver={handleDragoverOrLeave}
      onDragLeave={handleDragoverOrLeave}
      onDrop={handleDrop}>
      {children ? (
        <>
          {children}
          <div style={{ display: "none"}}>
            <input
              ref={refFileElement}
              onChange={handleChange}
              type="file"
              multiple={multiple} />
          </div>
        </>
      ) :
        (
          <div
            className={`${style.dragzone} ${dragging ? style.dragging : ""}`}>
            ここにファイルをドロップ<br />
            または<br />
            <input
              ref={refFileElement}
              onChange={handleChange}
              type="file"
              multiple={multiple} />
          </div>
        )}
    </div >
  )
};

export default forwardRef(ChildComponent);
