import { useEffect, useState } from 'react'
import { Favorite } from '../../models/FixText/Types'
import FavoriteManager from '../../utils/FavoriteManager'

function useFavorite() : { 
  favourites: Favorite[],
  add: (favorite: Favorite) => void,
  find: (id: string) => Favorite | null,
  update: (favorite: Favorite) => void,
  remove: (id: string) => void,
} {
  const [favourites, setFavourites] = useState<Favorite[]>(() =>
    FavoriteManager.getList())

  const add = (favorite: Favorite) => {
    FavoriteManager.add(favorite)
    setFavourites(FavoriteManager.getList())
  }

  const remove = (id: string) => {
    FavoriteManager.delete(id)
    setFavourites(FavoriteManager.getList())
  }

  const update = (favorite: Favorite) => {
    FavoriteManager.update(favorite)
    setFavourites(FavoriteManager.getList())
  }

  const find = (id: string) => {
    return favourites.find(x => x.id == id) ?? null
  }

  return {
    favourites,
    add,
    find,
    update,
    remove,
  }
}

export default useFavorite