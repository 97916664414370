import React, { useEffect } from 'react'
import Index from '../../components/ui-templates/Exif/Index'

function Exif() {
  useEffect(() => {
    document.title = "画像のEXIF情報参照 | ちょっと便利なツール";
  }, [])
  
  return (
    <>
      <Index/>
    </>
  )
}

export default Exif