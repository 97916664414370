import React, { useState } from 'react'

interface InputProp {
  value: string,
  setValue: (newValue: string) => void
}

/**
 * 語尾チェッカーの入力部分
 */
function Input({
  value,
  setValue,
}: InputProp) {
  return (
    <div className='panel'>
      <h2 className="is-size-5 panel-heading">入力</h2>
      <div className="panel-block">
        <textarea
          value={value}
          onChange={e => setValue(e.target.value)}
          className="textarea"
          rows={7}
        ></textarea>
      </div>
    </div>
  )
}

export default Input