import { GifOption, ImageFile } from "../../models/GifCreate/Types";
import StringUtil from "../../utils/StringUtil";
import { encode64 } from "./b64";
import GIFEncoder from "./GIFEncoder";

export default async function create_gif(
  canvas: HTMLCanvasElement,
  config: GifOption,
  files: ImageFile[]): Promise<string | null> {
  const ctx = canvas.getContext('2d')
  if (!ctx) return null

  //GIFEncoderの初期処理
  /* @ts-ignore */
  const encoder = new GIFEncoder();
  encoder.setRepeat(config.loopCount); //繰り返し回数 0=無限ループ
  encoder.setDelay(config.ms); //1コマあたりの待機秒数（ミリ秒）
  encoder.start();

  //画像ファイル一覧を取得
  //canvasのサイズを1枚目のコマに合わせる
  canvas.width = config.width;
  canvas.height = config.height;

  console.log(config.color)
  //全ての画像をcanvasへ描画
  for (let i = 0; i < files.length; i++) {
    const frame_no = config.sequence == "normal" ? i : files.length - 1 - i
    if (files[frame_no].target) {
      const dataUrl = files[frame_no].dataUrl as string
      const blob = StringUtil.toBlob(dataUrl.split(",")[1], "image/png")

      if (config.scaling == "cover") {
        /* @ts-ignore */
        drawImageCover(ctx,
          await window.createImageBitmap(blob),
          files[frame_no].naturalWidth,
          files[frame_no].naturalHeight)
      } else {
        drawImageScaled(
          await window.createImageBitmap(blob),
          files[frame_no].naturalWidth,
          files[frame_no].naturalHeight,
          config.color,
          ctx as CanvasRenderingContext2D)
      }
      encoder.addFrame(ctx); //コマ追加
    }
  }

  //アニメGIFの生成
  encoder.finish();
  return 'data:image/gif;base64,' + encode64(encoder.stream().getData());
}

function drawImageScaled(
  img: ImageBitmap,
  iw: number,
  ih: number,
  backColor: string,
  ctx: CanvasRenderingContext2D) {

  var canvas = ctx.canvas;
  var hRatio = canvas.width / img.width;
  var vRatio = canvas.height / img.height;
  var ratio = Math.min(hRatio, vRatio);
  var centerShift_x = (canvas.width - img.width * ratio) / 2;
  var centerShift_y = (canvas.height - img.height * ratio) / 2;

  ctx.clearRect(0, 0, canvas.width, canvas.height);
  ctx.fillStyle = backColor;
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.drawImage(img, 0, 0, img.width, img.height,
    centerShift_x, centerShift_y, img.width * ratio, img.height * ratio);
}

/* @ts-ignore */
function drawImageCover(ctx, img, iw, ih) {
  console.log(`iw=${iw} ih=${ih}`)

  var x = 0;
  var y = 0;
  var w = ctx.canvas.width;
  var h = ctx.canvas.height;

  // default offset is center
  // offsetX = typeof offsetX === "number" ? offsetX : 0.5;
  // offsetY = typeof offsetY === "number" ? offsetY : 0.5;
  var offsetX = 0;
  var offsetY = 0;

  // keep bounds [0.0, 1.0]
  if (offsetX < 0) offsetX = 0;
  if (offsetY < 0) offsetY = 0;
  if (offsetX > 1) offsetX = 1;
  if (offsetY > 1) offsetY = 1;

  var r = Math.min(w / iw, h / ih),
    nw = iw * r,   // new prop. width
    nh = ih * r,   // new prop. height
    cx, cy, cw, ch, ar = 1;

  // decide which gap to fill    
  if (nw < w) ar = w / nw;
  if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh;  // updated
  nw *= ar;
  nh *= ar;

  // calc source rectangle
  cw = iw / (nw / w);
  ch = ih / (nh / h);

  cx = (iw - cw) * offsetX;
  cy = (ih - ch) * offsetY;

  // make sure source rectangle is valid
  if (cx < 0) cx = 0;
  if (cy < 0) cy = 0;
  if (cw > iw) cw = iw;
  if (ch > ih) ch = ih;

  // fill image in dest. rectangle
  ctx.drawImage(img, cx, cy, cw, ch, x, y, w, h);
}