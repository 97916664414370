import React, { useCallback, useEffect, useRef } from 'react'

/**
 * モーダルダイアログ
 */
function ModalDialog({
  onClose,
  open,
  children,
}: ModalDialogProp): JSX.Element {
  const ref = useRef<HTMLDialogElement>(null)

  useEffect(() => {
    //console.log(open)
    if (open && !ref.current?.open) {
      ref.current?.showModal()  //表示
    } else if (!open && ref.current?.open) {
      ref.current?.close()      //閉じる
    }
  }, [open])

  // 閉じるボタンクリック時の処理
  const handleClose = useCallback(
    () => ref.current?.close(),
    [ref],
  )

  return (
    <dialog ref={ref} onClose={onClose ?? (() => { })}>
      <div style={{ padding: "10px 0" }}>
        {children}
      </div>
      <div>
        <button onClick={handleClose}>閉じる</button>
      </div>
    </dialog>
  )
}

/**
 * プロパティ
 */
interface ModalDialogProp {
  /**
   * ダイアログクローズ時のイベント
   */
  onClose: () => void
  /**
   * ダイアログ表示状態（trueを設定するとダイアログが表示される）
   */
  open: boolean
  /**
   * ダイアログに表示するコンテンツ
   */
  children?: React.ReactNode
}

export default ModalDialog