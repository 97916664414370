import React, { useCallback, useRef, useState } from 'react';
import {
  DataSheetGrid, intColumn, keyColumn, textColumn
} from 'react-datasheet-grid';
import useFavorite from '../../../../core/hooks/useFavorite';
import { Column, Favorite } from '../../../../models/FixText/Types';
import style from './OptionContent.module.css';

interface OptionContentProp {
  defs: Column[],
  onChangeDefs: (value: Column[]) => void,
  onChangeFile: (file: File | null) => void,
  onExecute: () => void,
}

function OptionContent({
  defs,
  onChangeDefs,
  onChangeFile,
  onExecute,
}: OptionContentProp) {

  //スプレッド列定義
  const columns = [
    { ...keyColumn('name', textColumn), title: '項目名' },
    { ...keyColumn('size', intColumn), title: 'サイズ', width: '0 0 100px' },
  ]

  //お気に入り関連
  const {favourites, add, find, update, remove} = useFavorite()
  const [selectedFavId, setSelectedFavId] = useState("")
  const [newFavName, setNewFavName] = useState("")
  const handleAddFav = useCallback(
    () => {
      const f = { name: newFavName, columns: defs } as Favorite
      add(f)
      setSelectedFavId(f.id)
    },
    [add, defs, newFavName],
  )
  const handleUpdateFav = useCallback(
    () => {
      const f = { id: selectedFavId, name: newFavName, columns: defs } as Favorite
      update(f)
    },
    [update, defs, newFavName, selectedFavId],
  )
  const handleDeleteFav = useCallback(
    () => {
      remove(selectedFavId)
      setSelectedFavId("")
    },
    [remove, selectedFavId],
  )
  
  const handleChangeFavSelect = useCallback(
    (e: any) => {
      setSelectedFavId(e.target.value)
      if (e.target.value) {
        const item = find(e.target.value)
        if (item) onChangeDefs(item.columns)
      }
    },
    [find],
  )

  //ファイル選択参照
  const fileElemRef = useRef<HTMLInputElement>(null)

  /**
   * ファイル変更イベント
   * @param e 入力イベント
   */
  const onFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const elem = fileElemRef.current
    if (elem && elem.files && elem.files.length > 0) {
      const file = elem.files[0]
      onChangeFile(file)
    } else {
      onChangeFile(null)
    }
  }

  return (
    <div className={style.contentWrapper}>
      <div className={style.defWrapper}>
        <p>STEP1：項目の定義</p>
        <div>
          <label>お気に入りから選択：</label>
          <select value={selectedFavId} onChange={handleChangeFavSelect}>
            <option value={""}>未選択</option>
            {favourites.map((data) => {
              return <option key={data.id} value={data.id}>{data.name}</option>;
            })}
          </select>
        </div>
        <DataSheetGrid
          value={defs}
          autoAddRow={true}
          onChange={(newDefs) => onChangeDefs(newDefs as Column[])}
          columns={columns}
          createRow={() => ({ name: "新しい項目", size: 0 })}
          height={400}
        />
        <div>
          <label>お気に入り名：</label>
          <input type="text" 
            value={newFavName} 
            onChange={(e) => setNewFavName(e.target.value)} />
          <button 
            onClick={handleAddFav}
            disabled={(newFavName?.length ?? 0) == 0}
          >登録</button>
          {selectedFavId && (
            <>
              <button onClick={handleUpdateFav}>変更</button>
              <button onClick={handleDeleteFav}>削除</button>
            </>
          )}
        </div>
      </div>
      <div className={style.fileWrapper}>
        <p>STEP2：ファイルを選択</p>
        <input
          ref={fileElemRef}
          type="file"
          onChange={onFileInputChange} />

        <div className={style.buttons}>
          <button 
            onClick={onExecute}
          >実行</button>
        </div>
      </div>
    </div>
  )
}

export default OptionContent;
