

  /**
   * 文の情報を管理するクラス
   */
  export class Sentence {
    value: string = ""
    endings: string = ""
    endingsWithoutPunctuation = ""
    duplicate: boolean = false
    duplicateKutoten: boolean = false
    withoutEndings: string = ""
    cnt: number = 0
    lineBreakAfter: boolean = false

    constructor(options: any) {
      if (options) {
        Object.assign(this, options)
      }
    }
  }
  
  /**
   * 文の情報を管理するクラス
   */
  export class CheckOption {
    duplicateThreshold: number = 3
    duplicateKutotenThreshold: number = 3
    endChars: string[] = ['。', '！', '？']

    constructor(options: any) {
      if (options) {
        Object.assign(this, options)
      }
    }
  }


