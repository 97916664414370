import React, { useEffect, useMemo, useRef } from 'react';
import { Column, useBlockLayout, useTable } from 'react-table';
import { VariableSizeGrid, GridOnScrollProps } from 'react-window';
import css from './Style.module.css';

/**
 * スクロールバーの幅を取得する関数
 * @returns すクローバーの幅(px)
 */
const scrollbarWidth = () => {
  const scrollDiv = document.createElement('div')
  scrollDiv.setAttribute('style', 'width: 100px; height: 100px; overflow: scroll; position:absolute; top:-9999px;')
  document.body.appendChild(scrollDiv)
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth
  document.body.removeChild(scrollDiv)
  return scrollbarWidth
}

/**
 * プロパティのインターフェイス
 */
interface TableProp {
  columns: Column<object>[]
  data: any[]
  width: number
  height: number
}

/**
 * テーブルコンポーネント
 * @param TableProp 引数
 * @returns コンポーネント
 */
function Table({
  columns,
  data,
  width,
  height,
}: TableProp) {
  const defaultColumn = React.useMemo(
    () => ({
      width: 150,
    }),
    []
  )
  //参照
  const refTHead = useRef<HTMLDivElement>(null)
  const refGrid = useRef<VariableSizeGrid>(null)
  //スクロールバーの幅
  const scrollBarSize = useMemo(() => scrollbarWidth(), [])
  //トータルの横幅
  const totalWidth = useMemo(() => 
    columns.reduce((prev, current) => prev + parseInt((current.width ?? 100) as any), 0)
  , [columns])

  //react-tableの定義
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useBlockLayout
  )

  //console.log(columns)
  useEffect(() => {
    refGrid.current?.resetAfterColumnIndex(0)
  }, [columns])
    
  //ヘッダと明細部のスクロール同期
  const handleScroll = ({ scrollLeft }: GridOnScrollProps) => {
    if (refTHead.current) refTHead.current.scrollLeft = scrollLeft
  }

  //セルの描画
  const Cell = ({ columnIndex, rowIndex, style }: any) => {
    const row = rows[rowIndex]
    prepareRow(row) 
    return (
      <div style={style} className={css.td}>
        {row.cells[columnIndex].render('Cell')}
      </div>
    )
  };

  return (
    <div {...getTableProps()}
      className={css.table}
      style={{ width: `${width}px` }}>
      <div className={css.thead_wrapper}>
        <div
          ref={refTHead}
          className={css.thead}
          style={{ width: `${width - scrollBarSize -2}px` }}>
          {headerGroups.map(headerGroup => (
            <div {...headerGroup.getHeaderGroupProps()} className={css.tr}>
              {headerGroup.headers.map(column => (
                <div {...column.getHeaderProps()} className={css.th}>
                  {column.render('Header')}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <div {...getTableBodyProps()}>
        <VariableSizeGrid
          ref={refGrid}
          columnCount={columns.length}
          columnWidth={i => {
            if (i == columns.length - 1 && (totalWidth + scrollBarSize) < width) {
              const w = width - (totalWidth + scrollBarSize)
              return parseInt((columns[i].width ?? 100) as any) + w
            } else {
              return parseInt((columns[i].width ?? 100) as any)
            }
          }}
          height={height}
          rowCount={rows.length}
          rowHeight={row => 35}
          width={width}
          className={css.tbody}
          onScroll={handleScroll}
          itemData={rows}
        >
          {Cell}
        </VariableSizeGrid>
      </div>
    </div>
  )
}

export default Table