import React, { useEffect, useState } from 'react'
import Frames from '../../components/ui-templates/GifCreate/Frames/Frames'
import Preview from '../../components/ui-templates/GifCreate/Preview/Preview'
import { ImageFile } from '../../models/GifCreate/Types'

function GifCreate() {
  useEffect(() => {
    document.title = "Gifアニメーション作成 | ちょっと便利なツール";
  }, [])

  const [files, setFiles] = useState<ImageFile[]>([])
  const [selectedIndex, setSelectedIndex] = useState(-1)

  return (
    <>
      <Frames 
        files={files} 
        onChange={(newFiles) => setFiles(newFiles)} 
        selectedIndex={selectedIndex}
        onSelectionIndexChange={setSelectedIndex}/>
      <Preview 
        selectedIndex={selectedIndex} 
        onSelectionIndexChange={setSelectedIndex}
        files={files}  />
    </>
  )
}

export default GifCreate