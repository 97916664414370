import React, { createRef, RefObject, useCallback, useEffect, useRef, useState } from 'react'
import { ImageFile } from '../../../../models/GifCreate/Types'
import FileDropzone, { FileDropzoneHandles } from '../../../ui-parts/FileDropzone/FileDropzone'
import style from './Style.module.css';
import { FaAngleLeft, FaAngleRight, FaAngleDoubleLeft, FaAngleDoubleRight, FaPlus } from "react-icons/fa";

interface FramesProp {
  files: ImageFile[]
  onChange: (images: ImageFile[]) => void
  selectedIndex: number
  onSelectionIndexChange: (index: number) => void
}

function Frames({
  files,
  onChange,
  selectedIndex,
  onSelectionIndexChange,
}: FramesProp) {

  //ファイルドロップ部品の ref
  const refFileDropZone = useRef<FileDropzoneHandles>(null)
  //ref参照
  //画像リストのref
  const imageRefs = useRef<RefObject<HTMLDivElement>[]>([])
  imageRefs.current = files.map((_, i) => imageRefs.current[i] ?? createRef());
  //画像リストフレームのref
  const frameRef = useRef<HTMLDivElement>(null)

  /**
   * ファイル選択の変更
   */
  const handleChange = useCallback(
    async (files: FileList) => {
      if (files) {
        await readFiles(files)
      }
    },
    [files],
  )

  /**
   * 画像選択
   */
  const handleSelect = useCallback(
    (index: number) => {
      onSelectionIndexChange(index)
    },
    [selectedIndex],
  )

  /**
   * 画像削除
   */
  const handleRemove = useCallback(
    () => {
      onChange(files.filter((x, i) => i != selectedIndex))
      onSelectionIndexChange(selectedIndex >= files.length - 1 ? -1 : selectedIndex)
    },
    [selectedIndex, files],
  )

  /**
   * 画像移動
   */
  const handleMove = useCallback(
    (direction: "leftmost" | "left" | "right" | "rightmost") => {
      const tmp = files[selectedIndex]
      const newList = files.filter((x, i) => i != selectedIndex)

      if (direction == "leftmost") {
        //先頭へ
        newList.splice(0, 0, tmp)
        onSelectionIndexChange(0)
      } else if (direction == "left") {
        //左へ
        newList.splice(selectedIndex - 1, 0, tmp)
        onSelectionIndexChange(selectedIndex - 1)
      } else if (direction == "right") {
        //右へ
        newList.splice(selectedIndex + 1, 0, tmp)
        onSelectionIndexChange(selectedIndex + 1)
      } else if (direction == "rightmost") {
        //最後へ
        newList.push(tmp)
        onSelectionIndexChange(newList.length - 1)
      }

      onChange(newList)
      setTimeout(() => {
        console.log(selectedIndex)
      }, 10);
    },
    [selectedIndex, files],
  )

  /**
   * ファイル追加クリック
   */
  const handleAddFile = useCallback(
    () => {
      refFileDropZone.current?.openDialog()
    },
    [refFileDropZone],
  )

  /**
   * 選択インデックス変更時のエフェクト
   */
  useEffect(() => {
    //console.log("selectedIndex=" + imageRefs.current.length)
    if (!frameRef.current) return;

    if (selectedIndex >= 0 && imageRefs.current.length > selectedIndex) {
      const left = imageRefs.current[selectedIndex].current?.offsetLeft ?? 0
      const width = imageRefs.current[selectedIndex].current?.offsetWidth ?? 0
      const sl = frameRef.current.scrollLeft
      const sw = frameRef.current.clientWidth
      const gap = 12 + 8

      if (left < sl) {
        frameRef.current.scrollLeft = left
      } else if (sl + sw < left + width + gap) {
        frameRef.current.scrollLeft = sl + ((left + width + gap) - (sl + sw))
      }
      // console.log(`left=${left}`)
      // console.log(`width=${width}`)
      // console.log(`sl=${width}`)
      // console.log(`sw=${sw}`)
    }
  }, [selectedIndex])


  /**
   * ファイルの追加
   * @param flist ファイルリスト
   */
  const readFiles = async (flist: FileList) => {
    const readFiles: ImageFile[] = []
    const maxSeq = files.length > 0 ? files.reduce((a, b) => a.seq > b.seq ? a : b).seq : 0
    let seq = maxSeq

    for (let i = 0; i < flist.length; i++) {
      const f = new ImageFile()
      f.type = flist[i].type
      const mimeType = f.type.split("/")
      f.target = mimeType.length >= 1 && mimeType[0] == "image"
      f.seq = ++seq
      if (f.target) {
        f.dataUrl = await readFileOne(flist[i])
      }
      readFiles.push(f)
    }

    //console.log(readFiles)
    onChange([...files, ...readFiles])
  }

  /**
   * ファイルの読み込み
   * @param f ファイル
   * @returns 
   */
  const readFileOne = (f: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        const { result } = reader;
        resolve(result as string);
      });
      reader.addEventListener("error", () => reject(reader.error));
      reader.readAsDataURL(f);
    });
  }

  /**
   * イメージ読み込み
   */
  const handleImageLoad = useCallback(
    (index: number, img: HTMLImageElement) => {
      //console.log(`handleImageLoad: index=${index}`)
      onChange(files.map((x, i) => {
        if (index == i) {
          x.naturalHeight = img.naturalHeight
          x.naturalWidth = img.naturalWidth
        }
        return x
      }))
    },
    [imageRefs.current, files],
  )

  return (
    <div className="panel">
      <p className="panel-heading">
        INPUT
      </p>
      <div>
        <div className={style.frameContainer}>
          {/* フレームリストのツールバー */}
          <div className={style.frameBar}>
            <div className={style.buttonGroup}>
              {/* ファイルを追加 */}
              <button
                onClick={handleAddFile}
                className="button is-info is-small is-outlined">
                <span className="icon is-small">
                  <FaPlus />
                </span>
                <span>追加</span>
              </button>
            </div>

            <div className={style.buttonGroup}>
              <div className="field has-addons">
                {/* 先頭 */}
                <p className="control">
                  <button
                    className="button is-small"
                    onClick={() => handleMove("leftmost")}
                    disabled={selectedIndex <= 0}
                  >
                    <span className="icon is-small">
                      <FaAngleDoubleLeft />
                    </span>
                    <span>先頭へ</span>
                  </button>
                </p>

                {/* 左へ */}
                <p className="control">
                  <button
                    className="button is-small"
                    onClick={() => handleMove("left")}
                    disabled={selectedIndex <= 0}
                  >
                    <span className="icon is-small">
                      <FaAngleLeft />
                    </span>
                    <span>左へ</span>
                  </button>
                </p>

                {/* 右へ */}
                <p className="control">
                  <button
                    className="button is-small"
                    onClick={() => handleMove("right")}
                    disabled={selectedIndex == -1 || selectedIndex == files.length - 1}
                  >
                    <span>右へ</span>
                    <span className="icon is-small">
                      <FaAngleRight />
                    </span>
                  </button>
                </p>
                {/* 最後へ */}
                <p className="control">
                  <button
                    className="button is-small"
                    onClick={() => handleMove("rightmost")}
                    disabled={selectedIndex == -1 || selectedIndex == files.length - 1}
                  >
                    <span>最後へ</span>
                    <span className="icon is-small">
                      <FaAngleDoubleRight />
                    </span>
                  </button>
                </p>
              </div>
            </div>
            <div className={style.buttonGroup}>
              <div className="field has-addons">
                <p className="control">
                  <button
                    className="button is-small is-danger is-outlined"
                    disabled={selectedIndex < 0}
                    onClick={handleRemove}
                  >
                    <span>削除</span>
                  </button>
                </p>
              </div>
            </div>
          </div>

          {/* フレームリスト兼、ファイルドロップエリア */}
          <FileDropzone
            multiple={true}
            onChange={handleChange}
            autoClear={true}
            ref={refFileDropZone}>
            <div className={style.frames} ref={frameRef}>
              <div className={style.scrollContainer}>
                {/* ファイルリスト */}
                {files.map((f, i) => (
                  <div
                    className={`${style.imageWrapper} ${i == selectedIndex ? style.selected : ""}`}
                    key={i}
                    onClick={() => handleSelect(i)}
                    ref={imageRefs.current[i]}
                  >
                    {f.target ? (
                      <img src={f.dataUrl} onLoad={e => handleImageLoad(i, e.target as HTMLImageElement)} />
                    ) : (
                      <div className={style.noimg}>No Image</div>
                    )}
                  </div>
                ))}
                {/* 画像追加 */}
                <div className={style.imageWrapper}>
                  <div className={style.addImage} onClick={handleAddFile}>
                    <FaPlus />
                    <span>画像を追加</span>
                  </div>
                </div>
              </div>
            </div>
          </FileDropzone>
        </div>


        <div className={style.frameContainer}>

        </div>
      </div>
    </div>
  )
}

export default Frames