import React, { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { CheckOption } from '../../../../models/GobiChecker/Types';
import style from './Style.module.css';

interface OptionsProp {
  option: CheckOption
  onChange: (option: CheckOption) => void
}

function Options({
  option,
  onChange,
}: OptionsProp) {

  const [cnt, setCnt] = useState(option.duplicateThreshold)
  const [kutoutenCnt, setKutoutenCnt] = useState(option.duplicateKutotenThreshold)
  const [endChars, setEndChars] = useState(option.endChars)
  const [tabIndex, setTabIndex] = useState(0)
  const ref = useRef(true);

  //入力変更時に onChangeを発火
  useEffect(() => {
    if (ref.current) {
      ref.current = false;
      return;
    }
    onChange(new CheckOption({
      duplicateThreshold: cnt,
      endChars: endChars,
      duplicateKutotenThreshold: kutoutenCnt,
    }))
  }, [cnt, endChars, kutoutenCnt,])

  //警告数の変更
  const onChangeCnt = useCallback(
    (e: ChangeEvent) => {
      const el = e.target as HTMLSelectElement
      setCnt(parseInt(el.value))
    },
    [cnt],
  )

  //句読点の警告数の変更
  const onChangeKutotenCnt = useCallback(
    (e: ChangeEvent) => {
      const el = e.target as HTMLSelectElement
      setKutoutenCnt(parseInt(el.value))
    },
    [kutoutenCnt],
  )

  //文末記号のチェック状態変更
  const onChangeEndChars = useCallback(
    (e: ChangeEvent) => {
      const el = e.target as HTMLInputElement
      const c = el.dataset.char as string
      if (el.checked) {
        setEndChars(Array.from(new Set([...endChars, c])))
      } else {
        setEndChars(endChars.filter(x => x != c))
      }
    },
    [endChars],
  )

  const handleTabSelect = useCallback(
    (e: MouseEvent, index: number) => {
      setTabIndex(index)
    },
    [],
  )

  /**
   * 語尾チェックオプション
   */
  const optionGobi = useMemo(() => {
    return (
      <div>
        <div className='is-flex' style={{ gap: "1rem" }}>
          {/* 連続数 */}
          <div className="field">
            <label className="label">警告する語尾の連続数</label>
            <div className="control">
              <div className="select">
                <select value={cnt} onChange={onChangeCnt}>
                  {Array.from({ length: 10 }).map((x, i) => (
                    <option key={i} value={i + 1}>{i + 1}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          {/* 連続数 */}
          <div className="field ml-4">
            <label className="label">文末とする句点または記号</label>
            <div className="control">
              <label className="checkbox">
                <input
                  type="checkbox"
                  className='mr-1'
                  data-char={'。'}
                  checked={endChars.includes('。')}
                  onChange={onChangeEndChars} />。
              </label>
              <label className="checkbox">
                <input
                  type="checkbox"
                  className='mr-1'
                  data-char={'！'}
                  checked={endChars.includes('！')}
                  onChange={onChangeEndChars} />！
              </label>
              <label className="checkbox">
                <input
                  type="checkbox"
                  className='mr-1'
                  data-char={'？'}
                  checked={endChars.includes('？')}
                  onChange={onChangeEndChars} />？
              </label>
            </div>
          </div>
          {/* 連続数 */}
          <div className="field ml-4">
            <label className="label">警告する読点「、」の連続数</label>
            <div className="control">
              <div className="select">
                <select value={kutoutenCnt} onChange={onChangeKutotenCnt}>
                  {Array.from({ length: 10 }).map((x, i) => (
                    <option key={i} value={i + 1}>{i + 1}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }, [option])

  return (
    <>
      <div className='panel'>
        <h2 className="is-size-5 panel-heading">チェックオプション</h2>
        <div className="panel-block">
          <div>
            {/* <p>以下のタブから実行するチェックを選択し、オプションを指定したあと「チェック開始」ボタンをクリックしてください。</p>
            <div className="mt-4 tabs is-boxed">
              <ul>
                <li className={`${tabIndex == 0 ? 'is-active' : ''}`}>
                  <a onClick={e => handleTabSelect(e, 0)}>
                    <span>語尾の連続</span>
                  </a>
                </li>
                <li className={`${tabIndex == 1 ? 'is-active' : ''}`}>
                  <a onClick={e => handleTabSelect(e, 1)}>
                    <span>冗長表現</span>
                  </a>
                </li>
              </ul>
            </div> */}
            <div className={style.container}>
              {optionGobi}
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default Options