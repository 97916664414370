import PropTypes from 'prop-types'
import React, { Component } from 'react'
import JoinFiles from '../../components/ui-templates/JoinFiles/JoinFiles'

export class JoinText extends Component {
  
  static propTypes = {}

  componentDidMount(){
    document.title = "テキストファイル結合 | ちょっと便利なツール";
  }

  render() {
    return (
      <div>
        <JoinFiles/>
      </div>
    )
  }
}

export default JoinText