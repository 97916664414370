/** Gif画像サイズ一覧 */
export const ENDINGS_WORDS: string[] = [
  'です',
  'ます',
  'しょう',
  'した',
  'ない',
  'ある',
  'ろう',
  'せん',
  'さい',
  'よね',
  'える',
  'れる',
  'いる',
  'だった',
]