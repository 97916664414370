import EXIF from 'exif-js'
import React, { useCallback, useRef, useState } from 'react'
import FileDropzone from '../../ui-parts/FileDropzone/FileDropzone'
import ModalDialog from '../../ui-parts/ModalDialog/ModalDialog'
import style from './Style.module.css';

function Index() {

  const [values, setValues] = useState<{ [name: string]: any } | null>(null)
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState("")

  /**
   * ファイル選択時の処理
   */
  const onChange = useCallback(
    async (files: FileList): Promise<void> => {
      const promise = new Promise<void>((resolve, reject) => {
        //選択したファイルを Data URLに変換して、緯度・軽度を取得する getExif()関数を呼ぶ
        const reader = new FileReader()
        reader.addEventListener("load", async () => {
          try {
            await getExif(reader.result as string)
            resolve()
          } catch (error) {
            reject(error)
          }
        }, false);
        reader.readAsDataURL(files[0]);
      })
      return promise.catch(err => {
        setMessage("読み込みエラー。選択したファイルを確認してください。")
        setOpen(true)
        console.log(err)
      })
    },
    []
  )

  /**
   * EXIF情報から緯度・軽度を取得する
   * @param url 画像のData URL
   */
  function getExif(url: string) {
    return new Promise((resolve, reject) => {
      //Data URLから Imageを作成
      const image = new Image()
      image.src = url
      image.onerror = (e) => reject(e)
      image.onload = function () {

        try {
          EXIF.getData(image as any, function () {
            //EXIF情報の緯度・経度タグを取得
            // const gpsLatitude = EXIF.getTag(image, 'GPSLatitude')
            // const gpgLongitude = EXIF.getTag(image, 'GPSLongitude')

            // //度分秒を10進数に変換
            // const lat = gpsLatitude[0] / 1 + gpsLatitude[1] / 60 + gpsLatitude[2] / 3600
            // const lon = gpgLongitude[0] / 1 + gpgLongitude[1] / 60 + gpgLongitude[2] / 3600
            // console.log(`緯度=${lat},経度=${lon}`)
            const all = EXIF.getAllTags(image)
            const wkValues: { [name: string]: any } = {}

            Object.keys(all).forEach(key => {
              if (key === "GPSLatitude" || key === "GPSLongitude") {
                const ary = EXIF.getTag(image, key)
                const tt = ary[0] / 1 + ary[1] / 60 + ary[2] / 3600
                wkValues[key] = JSON.stringify(all[key], null, 2)
                wkValues[key + "(10進表記)"] = tt
              } else if (typeof all[key] === "object" || Array.isArray(all[key])) {
                wkValues[key] = JSON.stringify(all[key], null, 2)
              } else {
                wkValues[key] = all[key]
              }
            })

            setValues(wkValues)
            resolve(null)
          })
        } catch (error) {
          reject(error)
        }
      }
    })
  }


  return (
    <div>
      <FileDropzone onChange={onChange} />
      <ModalDialog open={open} onClose={() => {
        setOpen(false)
      }}>
        <div>{message}</div>
      </ModalDialog>
      <div>
        {values && (
          <>
            <h3>取得結果</h3>
            <table className={style.exif_table}>
              <thead>
                <tr>
                  <th>項目</th>
                  <th>値</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(values).map((key, index) => (
                  <tr key={index}>
                    <td>{key}</td>
                    <td><code><pre>{values[key]}</pre></code></td>
                  </tr>
                ))}
                {Object.keys(values).length === 0 && (
                  <tr>
                    <td colSpan={2}>EXIF情報なし</td>
                  </tr>
                )}
              </tbody>
            </table>
          </>
        )}
      </div>
    </div>

  )
}

export default Index