

import { Console } from 'console';
import React, { MouseEvent, useCallback, useEffect, useRef, useState } from 'react'
import Input from '../../components/ui-templates/GobiChecker/Input/Input';
import Options from '../../components/ui-templates/GobiChecker/Options/Options';
import Result from '../../components/ui-templates/GobiChecker/Result/Result';
import { ENDINGS_WORDS } from '../../models/GobiChecker/Constant';
import { CheckOption, Sentence } from '../../models/GobiChecker/Types';

/**
 * 語尾チェッカー
 */
function GobiChecker() {
  useEffect(() => {
    document.title = "語尾チェッカー | ちょっと便利なツール";
  }, [])
  

  const [value, setValue] = useState(
    '語尾で同じ音が3回続いたら、語尾を変えます。\n' +
    '\n' +
    '2回までは大丈夫だと思います。\n' +
    'でも、3回続くと違和感があります！しかも幼稚な印象を与えます。\n' +
    '語尾チェッカーを使って、文章を作成後に見直しましょう。'
  )

  const [sentences, setSentences] = useState<Sentence[]>([])
  const [option, setOption] = useState(new CheckOption({}))

  const handleCheck = useCallback(
    () => {
      getLines()
    },
    [value, option],
  )

  const getLines = () => {
    const punctuations = option.endChars  //分の区切り文字
    const lines = value.replaceAll(/\r\n/g, '\n').split('\n');  //改行コードで分割した配列を作成
    //console.log("punctuations", punctuations)

    const ls = lines.map(line => {
      if (!line == true) return [line] //空行

      //文の区切り（。！？）でさらに分割した２次元リストを作成
      return punctuations.reduce((p, c, i) => {
        const punctuation = punctuations[i]
        return p.flatMap(word => {
          const split = word.split(punctuation)
          return split
            .map((text, i) => (split.length - 1 == i) ? text : text + punctuation)
            .filter(text => text != '')
        })
      }, [line])
    })

    //console.log(ls)
    //語尾検索用の正規表現作成
    const regex = new RegExp(`(${ENDINGS_WORDS.join('|')})(${punctuations.join('|')})?$`)

    //分割した文でSentenceクラスの配列を作成
    const tmpSentences: Sentence[] = []
    ls.forEach((sentenceTexts) => {
      //行の中の文ごとに語尾の抽出と Sentenceの作成をする
      tmpSentences.push(...sentenceTexts.map((sentence, i) => {
        //console.log(sentence)
        const m = sentence.match(regex)
        let endings = ''

        //console.log(m)
        if (m != null) {
          //定義されている語尾
          endings = m[0]
        } else {
          //定義外の語尾（後ろ１文字を語尾とする）
          endings = punctuations.includes(sentence.slice(-1)) ?
            sentence.slice(-2) :
            sentence.slice(-1)
        }

        //句読点抜きの語尾
        const wo = punctuations.includes(endings.slice(-1)) ?
          endings.substring(0, endings.length - 1) :
          endings

        //文（語尾なし）
        const withoutEndings = sentence.substring(0, sentence.length - endings.length)

        return new Sentence({
          value: sentence,
          endings: endings,
          endingsWithoutPunctuation: wo,
          withoutEndings: withoutEndings,
          lineBreakAfter: (sentenceTexts.length - 1) == i , //後ろで改行
        })
      }))
    })

    //語尾の重複の設定
    const ary: Sentence[] = []
    tmpSentences.forEach(s => {
      if (s.value == null || s.value == "") return
      if (ary.length == 0) {
        ary.push(s)
        return
      }

      if (ary[0].endingsWithoutPunctuation == s.endingsWithoutPunctuation) {
        s.cnt = ary.length
        ary.push(s)

        if (ary.length >= option.duplicateThreshold) {
          ary.forEach(x => x.duplicate = true)
        }
      } else {
        ary.length = 0
      }
    })

    //句読点の重複設定
    tmpSentences.forEach(s => {
      const cnt = s.withoutEndings.split("、").length - 1
      s.duplicateKutoten = cnt >= option.duplicateKutotenThreshold 
    })

    setSentences(tmpSentences)
  }

  return (
    <div>
      <p className='is-size-6'>
        このツールは、文章の語尾（「です」「ます」など）が連続している箇所を見つけるのに役立つツールです。一般的に同じ語尾が３回以上続くと文章が短調で読みにくいとされるので、文章を公開する前にこのツールを使ってチェックしておきましょう。
      </p>
      <div className='mt-2'>
        <Input value={value} setValue={setValue} />
      </div>
      <div className='mt-4'>
        <Options option={option} onChange={o => setOption(o)} />
      </div>
      <div className='mt-4'>
        <button className='button is-info' onClick={handleCheck}>チェック開始</button>
        <div className='mt-3'>
          <Result value={value} sentences={sentences} />
        </div>
      </div>
    </div>
  )
}

export default GobiChecker