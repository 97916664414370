export class ImageFile {
  constructor() {
  }
  dataUrl: string | undefined
  type: string | undefined
  target: boolean = false
  naturalWidth: number = 0
  naturalHeight: number = 0
  seq: number = 0
}

export interface GifSize {
  id: number
  name: string
  width: number
  height: number
}

export type SequenceType = "normal" | "reversal"
export type ScalingType = "contain" | "cover"

export interface GifOption {
  width: number
  height: number
  ms: number
  loopCount: number
  sequence: SequenceType
  scaling: ScalingType
  color: string
}