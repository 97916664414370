import React, { MouseEventHandler, useCallback, useContext } from 'react';
import { FaRegSadCry, FaTools } from 'react-icons/fa';
import { FiMenu } from 'react-icons/fi';
import { AppContext } from '../../../App';
import style from './Style.module.css';

interface HeaderProp {
  height?: string
}

//// <header className={style.header} style={{height: height}}>ちょっと便利なツール</header>

function Header({
  height
}: HeaderProp) {
  const {menuOpen, setMenuOpen} = useContext(AppContext)

  const handleMenuOepn = useCallback(
    () => {
      setMenuOpen(!menuOpen)
    },
    [menuOpen],
  )

  return (
    <header className={style.header}>
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <div className={`${style.menuOpener} is-align-self-center`}>
            <button onClick={handleMenuOepn}>
              <FiMenu size={20} color="#444" />
            </button>
          </div>
          <a className="navbar-item ml-4" href="/">
            <FaRegSadCry color='#443399' size={20} />
            <span className={`ml-2 ${style.headerTitle}`}>ちょっと便利なツール</span>
          </a>
        </div>
      </nav>
    </header>
  );
}

export default Header;
