export class InputFile {
  constructor() {
  }

  name: string | undefined
  binaryString : string | undefined
  buffer: ArrayBuffer | undefined
  size: number | undefined
  type: string | undefined
  target: boolean = false
}