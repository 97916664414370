import Papa, { parse } from 'papaparse';
import { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FaFileCsv } from 'react-icons/fa';
import { FiDownload } from 'react-icons/fi';
import Table from '../../components/atom/Table/Table';
import Encoding from 'encoding-japanese'

function TestPage() {

  //CSVから読み込んだデータ
  const [csvData, setCsvData] = useState<Papa.ParseResult<any> | null>(null)

  //CSVの１行目をヘッダ行とするか
  const [headerFirst, setHeaderFirst] = useState<boolean>(true)

  //選択列のインデックス
  const [checkedColumnIndex, setCheckedColumnIndex] = useState(-1)

  //登録中はtrueのフラグ
  const [isRegistering, setIsRegistering] = useState(false)

  //表示データの種類（db：DBから取得した複数ロット、manual：CSVファイルから読み込んだデータ）
  const [dataType, setDataType] = useState<"db" | "manual">("db")

  //ファイル選択要素への参照
  const refFile = useRef(null)

  //列数
  const columnCount = useMemo(() =>
    csvData?.data.length ?? 0 > 0 ? csvData?.data[0].length : 0
    , [csvData])

  //初期化
  useEffect(() => {
    const data = parse("ロットNo\n読み込み中...")
    setCsvData(data)

    const dataRead = async () => {
      const d = parse("ロットNo\n111111\n222222\n33333")
      setCsvData(d)
    }
    dataRead()
  }, [])

  //ファイルを選択
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files as FileList
    if (files.length == 0) return

    //FileReaderの準備
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const { result } = reader;

      //文字コードをunicodeへ変換
      const conv = Encoding.convert(
        new Uint8Array(result as ArrayBuffer),
        {
          to: "UNICODE",
          from: 'AUTO',
        }
      )
      const unicodeStr = Encoding.codeToString(conv)

      //ParaParseを使ってUnicode文字列のCSVをパース
      Papa.parse(unicodeStr, {
        complete: function (results) {
          // パースが完了したら、結果を表示する
          setDataType("manual")
          setCheckedColumnIndex(-1)
          setCsvData(results)
        }
      })
    })

    reader.readAsArrayBuffer(files[0])


    // const files = e.target.files as FileList
    // if (files.length == 0) return

    // //CSVをパース
    // Papa.parse(files[0], {
    //   complete: function (results) {
    //     // パースが完了したら、結果を表示する
    //     console.log(results);
    //     setDataType("manual")
    //     setCsvData(results)
    //   }
    // })
  };

  //ヘッダの描画
  const headerCellRender = useCallback(
    (index: number, headerText: string) => {
      const text = headerFirst ? headerText : `Column${index + 1}`
      return (
        <div>
          {(columnCount) > 1 && (
            <label className='is-flex is-flex-wrap-nowrap'>
              <input
                type="checkbox"
                checked={checkedColumnIndex == index}
                disabled={isRegistering}
                onChange={e => setCheckedColumnIndex(index)}
              />
              <span 
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  width: "100px"
                }}
              >{text}</span>
            </label>
          )}
          {(columnCount) == 1 && (
            <>{text}</>
          )}
        </div>)
    },
    [csvData, checkedColumnIndex, headerFirst, isRegistering],
  )

  //テーブルに表示する列の定義（CSVの１行目から作成）
  const columns = useMemo(() => {
    if (csvData == null || csvData.data.length == 0) {
      return [{ Header: 'No Data' }]
    }
    //１行目のデータで列の定義を作成
    const row = csvData.data[0] as Array<any>
    return row.map((cellData, columnIndex) => {
      return {
        //Header: headerFirst ? cellData : `Column${columnIndex+1}`,
        id: columnIndex + "",
        Header: () => headerCellRender(columnIndex, cellData),
        accessor: (row: any, i: number) => row[columnIndex],
        width: 160,
      }
    })
  }, [csvData, headerFirst, checkedColumnIndex, isRegistering])

  //ダウンロード処理
  const handleDownload = useCallback((e: MouseEvent) => {
    e.preventDefault()

    var csv = Papa.unparse({
      "fields": ["ロットNo"],
      "data": (csvData?.data ?? []).slice(1).map(x => [x[0]])
    })

    const strArray = Encoding.stringToCode(csv)
    const convertedArray = Encoding.convert(strArray, 'SJIS', 'UNICODE')
    const UintArray = new Uint8Array(convertedArray)
    const blobUrl = new Blob([UintArray], { type: 'text/csv' })

    const aTag = document.createElement('a')
    aTag.href = window.URL.createObjectURL(blobUrl)
    aTag.download = "複数ロット.csv"
    document.body.appendChild(aTag)
    aTag.click()
    document.body.removeChild(aTag)

  }, [csvData])

  //登録ボタンの処理
  const handleRegister = useCallback(
    async () => {
      setIsRegistering(true)
      return new Promise((resolve, reject) => {
        const index = columnCount == 1 ? 0 : checkedColumnIndex
        const lotlist = csvData?.data.slice(headerFirst ? 1 : 0).map(row => row[index])
        console.log(lotlist)
      })
    },
    [csvData, headerFirst, checkedColumnIndex],
  )

  return (
    <div>
      <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-content">
          <div className='box'>
            <div className='is-flex'>
              <p className='is-size-4 mb-2'>複数ロット指定</p>
              <div style={{ marginLeft: "auto" }}>
                <button
                  className="button is-small is-info is-outlined"
                  disabled={isRegistering}
                  onClick={() => {
                    if (refFile.current) {
                      const el = refFile.current as HTMLInputElement
                      el.click()
                    }
                  }}
                >
                  <FaFileCsv />
                  ファイルを選択
                </button>
                <input
                  ref={refFile}
                  type="file"
                  onChange={handleChange}
                  style={{ display: "none" }}
                />
              </div>
            </div>

            {columnCount > 1 && (
              <div className="notification is-warning is-light p-2 mt-1 mb-2">
                読み込んだCSVに複数の列が存在します。ロットNoとして読み込む列にチェックしてください。
              </div>
            )}

            {dataType == "manual" && (
              <div>
                <label>
                  <input type="checkbox"
                    onChange={(e) => setHeaderFirst(e.target.checked)}
                    checked={headerFirst}
                    className="mr-1"
                    disabled={isRegistering}
                  />CSVファイルの1行目をヘッダとして読み込む
                </label>
              </div>
            )}
            <Table
              columns={columns}
              data={csvData?.data.slice(headerFirst ? 1 : 0) ?? []}
              width={600}
              height={400} />

            <div className='is-flex'>
              <div>
                {dataType == "db" && (
                  <div className='mt-1'>
                    <a
                      href="#"
                      className='is-size-6'
                      onClick={handleDownload}
                    ><FiDownload /><span>ダウンロード</span></a>
                  </div>
                )}
              </div>

              <div className="buttons mt-3 is-right" style={{ marginLeft: "auto" }}>
                {dataType == "manual" && (
                  <button
                    className={`button is-link ${isRegistering ? 'is-loading' : ''}`}
                    disabled={!(columnCount == 1 || (columnCount > 1 && checkedColumnIndex != -1)) || isRegistering}
                    onClick={handleRegister}
                  >登録</button>
                )}
                <button 
                  className="button"
                  disabled={isRegistering}
                >キャンセル</button>
              </div>
            </div>
          </div>

        </div>
        <button
          className="modal-close is-large"
          aria-label="close"></button>
      </div>
    </div>
  )
}

export default TestPage