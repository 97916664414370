import { GifSize } from "./Types";


namespace ConstGifCreate {

  /** Gif画像サイズ一覧 */
  export const GIF_SIZE_LIST: GifSize[] = [
    {id: 1, name: "75 x 75", width: 75, height: 75},
    {id: 2, name: "120 x 120", width: 120, height: 120},
    {id: 3, name: "200 x 200", width: 200, height: 200},
    {id: 4, name: "120 x 240", width: 120, height: 240},
    {id: 5, name: "360 x 240", width: 360, height: 240},
    {id: 6, name: "640 x 960", width: 640, height: 960},
    {id: 7, name: "120 x 90", width: 120, height: 90},
    {id: 99, name: "カスタムサイズ", width: 0, height: 0},
  ]

  /** カスタムサイズのID */
  export const CUSTOM_SIZE_ID = 99

  /** プレビューの高さ */
  export const PREVIEW_HEIGHT = 400;
}

export default ConstGifCreate