import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { FaFile, FaImage, FaTable } from 'react-icons/fa';
import { RiFileGifFill } from "react-icons/ri";
import { FiMenu } from "react-icons/fi";
import { Link } from 'react-router-dom';
import SideMenuItem from '../../atom/SideItem/Sidetem'
import style from './Style.module.css';
import { AppContext } from '../../../App';

const iconStyles = {
  color: '#555',
  size: 18
}

/**
 * サイドメニューコンポーネント
 */
function Side() {
  const { menuOpen, setMenuOpen } = useContext(AppContext)
  const [animating, setAnimating] = useState(false)
  const insideRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const el = insideRef.current;
    if (!el) return

    const hundleClickOutside = (e: MouseEvent) => {
      if (!el.contains(e.target as Node) && !(e.target as HTMLElement).closest("header button")) {
        setMenuOpen(false)
      }
    };
    document.addEventListener("click", hundleClickOutside)
    return () => {
      document.removeEventListener("click", hundleClickOutside)
    }
  }, [insideRef]);

  useEffect(() => {
    setAnimating(true)
  }, [menuOpen])

  const transitionEnd = useCallback(
    () => {
      setAnimating(false)
      //console.log("transitionEnd")
    },
    [menuOpen],
  )
  
  const IconLink = (path: string, icon: any, text: string) => (
    <>
      <Link to={`${process.env.PUBLIC_URL}/${path}`}>
        <span className="icon-text">
          <span className="icon">
            {icon}
          </span>
          <span className={`ml-3 ${style.text}`}>{text}</span>
        </span>
      </Link>
    </>
  )

  return (
    <div 
      ref={insideRef} 
      className={`${style.rootContainer} ${menuOpen ? style.open : ""} ${animating ? style.animating : ""}`}
      onTransitionEnd={transitionEnd}>
      <aside className="menu">
        <ul className={`pt-2 menu-list ${style.menuList}`}>
          <li>
            {IconLink("fixtext.html", <FaTable {...iconStyles} />, "固定長ファイルビューワー")}
          </li>
          <li>
            {IconLink("jointext.html", <FaFile {...iconStyles} />, "テキストファイル結合")}
          </li>
          <li>
            {IconLink("exif.html", <FaImage {...iconStyles} />, "画像のEXIF情報参照")}
          </li>
          <li>
            {IconLink("gitcreate.html", <RiFileGifFill {...iconStyles} />, "Gifアニメーション作成")}
          </li>
          <li>
            {IconLink("gobichecker.html", <RiFileGifFill {...iconStyles} />, "語尾チェッカー")}
          </li>
        </ul>
      </aside>
      {/* <div>
        <SideMenuItem route={`${process.env.PUBLIC_URL}/fixtext`} text='固定長ファイルビューワー'/>
        <SideMenuItem route={`${process.env.PUBLIC_URL}/jointext`} text='テキストファイル結合'/>
        <SideMenuItem route={`${process.env.PUBLIC_URL}/exif`} text='画像のEXIF情報参照'/>
        <SideMenuItem route={`${process.env.PUBLIC_URL}/gitcreate`} text='GIT作成'/>
      </div> */}
    </div>
  )
}

export default Side