import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  DataSheetGrid,
  DynamicDataSheetGrid, intColumn, keyColumn, textColumn
} from 'react-datasheet-grid';
import 'react-datasheet-grid/dist/style.css';
import OptionContent from '../../components/ui-templates/FixTextReader/OptionContent/OptionContent';
import ResultView from '../../components/ui-templates/FixTextReader/ResultView/ResultView';
import StringUtil from '../../utils/StringUtil';
import style from './Style.module.css';

interface Column {
  name: string,
  size: number | null,
}

type DataRecode = { [key: string]: string }

function FixTextReader() {
  useEffect(() => {
    document.title = "固定長ファイルビューワー | ちょっと便利なツール";
  }, [])

  // const mobileQuery = useMobileQuery()
  // const { previewMode, setPreviewMode } = useContext(TextStateContext);
  const fileElemRef = useRef<HTMLInputElement>(null)

  const [defs, setDefs] = useState<Column[]>(() => {
    const json = localStorage.getItem("lastDefs")
    if (json) {
      const obj = JSON.parse(json)
      return obj as Column[]
    }

    return []
  })

  const dataColumns = useMemo(() => defs.filter(dev => dev.name).map((def, index) => {
    //console.log(defs)
    return { ...keyColumn(`key${index}`, textColumn), title: def.name }
  }), [defs])

  const [activePage, setActivePage] = useState(0)
  const [file, setFile] = useState<File | null>(null)
  const [data, setData] = useState<DataRecode[]>([])

  /**
   * 実行処理
   */
  const handleExecute = () => {
    if (file) {
      localStorage.setItem("lastDefs", JSON.stringify(defs))
      const reader = new FileReader()
      reader.readAsText(file, 'Shift-JIS')
  
      //ファイル読込後、内容をテキストエリアに出力する
      reader.onload = () => {
        splitText(reader.result as string)
        setActivePage(1)

      };
    }
  }

  /**
   * 文字列を定義に従って項目ごとに分解
   * @param text 
   */
  const splitText = (text: string) => {
    const lines = text.split(/\n/)
    setData(lines.map(splitLine))
  }

  /**
   * １行分の分解
   * @param line 
   * @returns 
   */
  const splitLine = (line: string): DataRecode => {
    let offset = 0
    let index = 0
    let obj: DataRecode = {}
    for (const def of defs) {
      const value = StringUtil.substrByte(line, offset, def.size ?? 0)
      obj[`key${index}`] = value
      offset += def.size ?? 0
      index++
    }
    return obj
  }

  return (
    <div>
      <ul className={style.tabs}>
        <li
          className={activePage == 0 ? style.active : ''}
          onClick={() => setActivePage(0)}
        >オプション</li>
        <li
          className={activePage == 1 ? style.active : ''}
          onClick={() => setActivePage(1)}>結果</li>
      </ul>
      <div className={style.contentWrapper}>
        {activePage == 0 && (
          <div className={style.area}>
            <OptionContent
              defs={defs}
              onChangeDefs={setDefs}
              onChangeFile={setFile}
              onExecute={handleExecute}
            />
          </div>
        )}
        {activePage == 1 && (
          <div className={style.area}>
            <ResultView
              data={data}
              dataColumns={dataColumns}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default FixTextReader;
