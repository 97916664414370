import { useCallback } from 'react';
import {
  DynamicDataSheetGrid
} from 'react-datasheet-grid';

type DataRecode = { [key: string]: string }
type DataColumn = { [key: string]: any }

interface ResultViewProp {
  data: DataRecode[],
  dataColumns: DataColumn[],
}

function ResultView({
  data,
  dataColumns,
}: ResultViewProp) {

  const handleCopy = useCallback(
    async () => {
      
      let clipData = ""

      dataColumns.forEach((c, index) => {
        if (index > 0) clipData += "\t"
        clipData += c['title']
      })
      clipData += "\n"

      data?.forEach((x, ri) => {
        if (ri > 0) clipData += "\n"
        dataColumns.forEach((c, index) => {
          if (index > 0) clipData += "\t"
          clipData += x[c['id']]
        })
      })

      await navigator.clipboard.writeText(clipData).catch(() => {
        console.log("失敗")
      })
      console.log("OK")
    },
    [data, dataColumns],
  )

  return (
    <div>
      <div style={{ textAlign: "left" }}>
        <button onClick={handleCopy}>クリップボードにコピー</button>
      </div>
      <DynamicDataSheetGrid
        value={data}
        columns={dataColumns}
        addRowsComponent={false}
        height={600}
      />
    </div>
  )
}

export default ResultView;
