import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Exif from '../../../pages/Exif/Exif';
import FixTextReader from '../../../pages/FixTextReader/FixTextReader';
import GifCreate from '../../../pages/GifCreate/GifCreate';
import GobiChecker from '../../../pages/GobiChecker/GobiChecker';
import JoinText from '../../../pages/JoinText/JoinText';
import TestPage from '../../../pages/Test/TestPage';
import Side from '../Side/Side';
import style from './Style.module.css';

interface MainGridProp {
  rootClassName?: string
}

function MainGrid({
  rootClassName
}: MainGridProp) {

  const location = useLocation()
  const [title, setTitle] = useState("")

  useEffect(() => {
    if (location.pathname == "/fixtext.html") {
      setTitle("固定長ファイルビューワー")
    } else if (location.pathname == "/jointext.html") {
      setTitle("テキストファイル結合")
    } else if (location.pathname == "/exif.html") {
      setTitle("画像のEXIF情報参照")
    } else if (location.pathname == "/gitcreate.html") {
      setTitle("GIF作成")
    } else if (location.pathname == "/gobichecker.html") {
      setTitle("語尾チェッカー")
    }

  }, [location])


  return (
    <div className={rootClassName}>
      <div className={style.grid}>
        <div className={style.side}>
          <Side />
        </div>
        <div className={style.main}>
          <nav className="mt-1 ml-1 breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li><a href="/">HOME</a></li>
              <li className="is-active"><a href="#" aria-current="page">{title}</a></li>
            </ul>
          </nav>
          <div className={style.routeContent}>
            <Routes>
              <Route path={`${process.env.PUBLIC_URL}/jointext.html`} element={<JoinText />} />
              <Route path={`${process.env.PUBLIC_URL}/fixtext.html`} element={<FixTextReader />} />
              <Route path={`${process.env.PUBLIC_URL}/exif.html`} element={<Exif />} />
              <Route path={`${process.env.PUBLIC_URL}/gitcreate.html`} element={<GifCreate />} />
              <Route path={`${process.env.PUBLIC_URL}/gobichecker.html`} element={<GobiChecker />} />
              <Route path={`${process.env.PUBLIC_URL}/test.html`} element={<TestPage />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>

  )
}

export default MainGrid