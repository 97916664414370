import { BrowserRouter } from 'react-router-dom';
import './App.css';
import 'bulma/css/bulma.css';
import Header from './components/ui-layouts/Header/Header';
import MainGrid from './components/ui-layouts/MainGrid/MainGrid';
import { createContext, useState } from 'react';
import { AppContextModel } from './models/Core/Types';

export const AppContext = createContext<AppContextModel>({} as AppContextModel)

function App() {
  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <AppContext.Provider value={{
      menuOpen,
      setMenuOpen
    }}>
      <div className="App">
        <Header height='2rem' />
        <BrowserRouter>
          <MainGrid rootClassName='contentRoot' />
        </BrowserRouter>
      </div>
    </AppContext.Provider>
  );
}

export default App;
