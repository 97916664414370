import { v4 as uuidv4 } from 'uuid';
import { Favorite } from '../models/FixText/Types';

/**
 * お気に入り管理
 */
export default class FavoriteManager {
  
  /**
   * お気に入りリスト取得
   * @returns 
   */
  static getList() : Favorite[] {
    const json = localStorage.getItem("Favorite")
    if (json) {
      const obj = JSON.parse(json)
      if (Array.isArray(obj)) {
        return obj as Favorite[]
      }
    }
    return []
  }

  /**
   * 追加
   * @param favorite 
   */
  static add(favorite: Favorite) : Favorite {
    const list = FavoriteManager.getList()
    favorite.id = uuidv4()
    list.push(favorite)
    FavoriteManager.__save(list)
    return favorite
  }

  /**
   * 更新
   * @param favorite 
   */
  static update(favorite: Favorite) : Favorite {
    const list = FavoriteManager.getList()
    const index = list.findIndex(x => x.id == favorite.id)
    if (index >= 0) {
      list.splice(index, 1, favorite)
    }
    FavoriteManager.__save(list)
    return favorite;
  }

  /**
   * 削除
   * @param id 
   */
   static delete(id: string) : void {
    const list = FavoriteManager.getList()
    const index = list.findIndex(x => x.id === id)
    if (index >= 0) {
      list.splice(index, 1)
      FavoriteManager.__save(list)
    }
  }

  /**
   * 保存
   * @param list 
   */
  static __save(list: Favorite[]) {
    const json = JSON.stringify(list)
    localStorage.setItem("Favorite", json)
  }
}